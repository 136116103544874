import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { LOGIN_MUTATION, RESTORE_PASSWORD_FINISH_MUTATION } from '../../../../../api/public/login';
import { Button, ErrorAlert } from '../../../../../components';
import { useAuth } from '../../../../../hooks/useAuth';
import FormHeader from '../../../components/FormHeader';

import styles from './RestorePasswordForm.module.scss';
import { FormikTextField } from '../../../../../components/inputs/FormikTextField/FormikTextField';

const ValidationSchema = Yup.object().shape({
  password: Yup.string().required('Введите пароль'),
  code: Yup.string().required('Введите код подтверждения'),
});

type Props = {
  email: string;
  goBack: () => void;
  checkId: string;
  onSuccess: () => void;
};

export default function RestorePasswordForm({ email, goBack, checkId, onSuccess }: Props) {
  const { loginUser } = useAuth();

  const [restoreFinish, { error, loading }] = useMutation(RESTORE_PASSWORD_FINISH_MUTATION, {
    onCompleted: () => {
      onSuccess();
      logIn({
        variables: {
          email,
          password: formik.values.password,
        },
      });
    },
    onError: (err) => {
      if (err.message === 'wrong code') {
        formik.setFieldError('code', 'Неверный код подтверждения');
      }
    },
  });

  const [logIn, { error: loginError }] = useMutation(LOGIN_MUTATION, {
    onCompleted: (data) => {
      // FIXME
      // @ts-ignore
      loginUser(data.login);
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      code: '',
    },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      restoreFinish({
        variables: {
          ...values,
          checkId,
        },
      });
    },
  });

  const noAutoComplete = {
    autoComplete: 'off'
  };
  
  return (
    <>
      <FormHeader action={goBack} title={'Восстановление пароля'} />
      <h3>{email}</h3>
      <h5>На вашу почту отправлено письмо с проверочным кодом</h5>
      <form className={styles.formBox} onSubmit={formik.handleSubmit}>
        <FormikTextField field="code" formik={formik} placeholder="Проверочный код" {...noAutoComplete} />
        <FormikTextField field="password" type="password" formik={formik} placeholder="Новый пароль"  />
        <div className={styles.action}>
          <Button variant='filled' onClick={() => formik.handleSubmit()} loading={loading}>
            Изменить
          </Button>
        </div>
      </form>
      <ErrorAlert
        error={error || loginError}
        mapMessage={{
          'Failed to fetch': 'Отсутствует соединение с сервером',
        }}
      />
    </>
  );
}
