import { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import {
  ErrorAlert,
  FormikAutocomplete,
  FormikPhoneInput,
  Button,
} from '../../../../components';
import { REGISTER_USER_MUTATION } from '../../../../api/public/login';
import { useAuth } from '../../../../hooks/useAuth';
import { useDispatch } from '../../../../store/hooks';
import { selectCity, setCity } from '../../../../store/mainSlice';
import { fetchCities } from '../../../../api/public/city';
import FormHeader from '../../components/FormHeader';
import { useReferalLink } from '../../../../hooks';

import styles from './RegistrationForm.module.scss';
import { City } from '../../../../__generated__/graphql';
import { FormikTextField } from '../../../../components/inputs/FormikTextField/FormikTextField';

interface Values {
  city: City | null,
  firstname: string,
  surname: string,
  patronymic: string,
  phone: string,
};

const REQUIRED = 'Обязательное поле';

const ValidationSchema = Yup.object().shape({
  city: Yup.object({
    id: Yup.number(),
    name: Yup.string(),
  })
    .nullable()
    .required('Введите город'),
  firstname: Yup.string().required(REQUIRED),
  surname: Yup.string().required(REQUIRED),
  patronymic: Yup.string(),
  phone: Yup.string().min(17, 'Некорректный телефон').required(REQUIRED),
});

type Props = {
  email: string;
  goBack: () => void;
};

export default function RegistrationForm({ email, goBack }: Props) {
  const { loginUser } = useAuth();
  const selectedCity = useSelector(selectCity);
  const dispatch = useDispatch();
  const [managerId, clearManagerId] = useReferalLink();

  useEffect(() => {
    if (selectedCity) {
      formik.setFieldValue('city', selectedCity);
    }
    //eslint-disable-next-line
  }, [selectedCity]);

  const [registerUser, { loading, error }] = useMutation(REGISTER_USER_MUTATION, {
    onCompleted: (data) => {
      clearManagerId();
      // FIXME
      // @ts-ignore
      loginUser(data.register);
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const formik = useFormik<Values>({
    initialValues: {
      city: selectedCity ? selectedCity : null,
      firstname: '',
      surname: '',
      patronymic: '',
      phone: ''
    },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      handleRegisterUser(values);
    },
  });

  useEffect(() => {
    if (formik.values.city) {
      dispatch(setCity(formik.values.city));
    }
    //eslint-disable-next-line
  }, [formik.values.city]);

  const handleRegisterUser = (values: Values) => {
    registerUser({
      variables: {
        data: {
          cityId: Number(values.city?.id),
          email,
          phone: values.phone.replace(/\D/g, ''),
          firstname: values.firstname,
          surname: values.surname,
          patronymic: values.patronymic,
          managerId
        }
      },
    });
  };

  return (
    <>
      <form className={styles.formBox} onSubmit={formik.handleSubmit}>
        <FormHeader action={goBack} title={'Регистрация'} />
        <h4>{email}</h4>
        <div className={styles.container} >
          <div className={styles.field}>
            <FormikTextField required field="surname" formik={formik} placeholder="Фамилия *" />
          </div>
          <div className={styles.field}>
            <FormikTextField required field="firstname" formik={formik} placeholder="Имя *" />
          </div>
          <div className={styles.field}>
            <FormikTextField field="patronymic" formik={formik} placeholder="Отчество" />
          </div>
          <div className={styles.phone}>
            <FormikPhoneInput field="phone" formik={formik} required placeholder='Телефон *'/>
          </div>
          <div className={styles.city}>
            <FormikAutocomplete
              formik={formik}
              field="city"
              placeholder="Выберите город"
              // FIXME
              // @ts-ignore
              fetch={fetchCities}
              getLabel={(v: City) => `${v.name}${v.region ? ` (${v.region.name})` : ''}`}
            />
          </div>
        </div>
        <div className={styles.action}>
          <Button variant='filled' onClick={() => formik.handleSubmit()} loading={loading}>
            Войти
          </Button>
        </div>
      </form>
      <ErrorAlert
        error={error}
        mapMessage={{
          'Failed to fetch': 'Отсутсвует соединение с сервером',
          'phone used': 'Пользователь с таким номером уже зарегистрирован',
        }}
      />
    </>
  );
}
