import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLazyQuery } from '@apollo/client';
import { ErrorAlert, Button } from '../../../../components';
import { USER_IS_REGISTERED } from '../../../../api/public/login';

import styles from './EmailCheckForm.module.scss';
import { FormikTextField } from '../../../../components/inputs/FormikTextField/FormikTextField';

const ValidationSchema = Yup.object().shape({
  email: Yup.string().email('Некорректный email').required('Обязательное поле'),
});

type Props = {
  onCallback: (email: string, isRegistered: boolean) => void;
};

export default function EmailCheckForm({ onCallback }: Props) {
  const [getStatus, { loading, error }] = useLazyQuery(USER_IS_REGISTERED, {
    onCompleted: (data) => {
      onCallback(formik.values.email, data?.authIsRegistered);
    },
    fetchPolicy: 'no-cache',
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: ValidationSchema,
    onSubmit: (variables) => {
      getStatus({
        variables,
      });
    },
  });

  return (
    <>
      <form className={styles.formBox} onSubmit={formik.handleSubmit}>
        <h3>Вход / Регистрация</h3>
        <FormikTextField field="email" formik={formik} placeholder="Email" />
        <div className={styles.action}>
          <Button variant='filled' onClick={formik.handleSubmit} loading={loading}>
            Войти
          </Button>
        </div>
      </form>
      <ErrorAlert
        error={error}
        mapMessage={{
          'Failed to fetch': 'Отсутсвует соединение с сервером',
        }}
      />
    </>
  );
}
