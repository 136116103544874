import { useState } from 'react';
import { FormAgreement } from '../../components';
import EmailCheckForm from './forms/EmailCheckForm/EmailCheckForm';
import LoginForm from './forms/LoginForm/LoginForm';
import RegistrationForm from './forms/RegistrationForm/RegistrationForm';
import RestorePassword from './forms/RestorePassword/RestorePassword';

import styles from './AuthForm.module.scss';

enum Steps {
  'EmailCheck',
  'Login',
  'Registration',
  'RestorePassword',
}

export default function AuthForm() {
  const [email, setEmail] = useState<string>('');
  const [step, setStep] = useState<number>(Steps.EmailCheck);

  return (
    <div className={styles.container}>
      {step === Steps.EmailCheck && (
        <div className={styles.emailCheck}>
          <EmailCheckForm
            onCallback={(email: string, isRegistered: boolean) => {
              setEmail(email);
              setStep(isRegistered ? Steps.Login : Steps.Registration);
            }}
          />
          <FormAgreement />
        </div>
      )}
      {step === Steps.Login && (
        <div className={styles.login}>
          <LoginForm
            goBack={() => {
              setStep(Steps.EmailCheck);
            }}
            toRestorePass={() => {
              setStep(Steps.RestorePassword);
            }}
            email={email}
          />
          <FormAgreement />
        </div>
      )}
      {step === Steps.Registration && (
        <div className={styles.registration}>
          <RegistrationForm
            email={email}
            goBack={() => {
              setStep(Steps.EmailCheck);
            }}
          />
          <FormAgreement />
        </div>
      )}
      {step === Steps.RestorePassword && (
        <div className={styles.restorePass}>
          <RestorePassword email={email} goBack={() => setStep(Steps.EmailCheck)} />
          <FormAgreement />
        </div>
      )}
    </div>
  );
}
