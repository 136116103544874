import { gql } from "../../__generated__";

export const USER_IS_REGISTERED = gql(`
  query AuthIsRegistered($email: String!) {
    authIsRegistered(email: $email)
  }
`);

export const LOGIN_MUTATION = gql(`
  mutation Login($password: String!, $email: String!) {
    login(password: $password, email: $email) {
      token
      city {
        id
        name
        region {
          id
          name
          district {
            id
            name
          }
        }
      }
    }
  }
`);

export const REGISTER_USER_MUTATION = gql(`
  mutation Register($data: RegisterPayload!) {
    register(data: $data) {
      token
      city {
        id
        name
        region {
          id
          name
          district {
            id
            name
          }
        }
      }
    }
  }
`);

export const SEND_RESTORE_CODE_MUTATION = gql(`
  mutation RestorePasswordSendCode($email: String!) {
    restorePasswordSendCode(email: $email) {
      checkId
    }
  }
`);

export const RESTORE_PASSWORD_FINISH_MUTATION = gql(`
  mutation RestorePasswordFinish($password: String!, $code: String!, $checkId: String!) {
    restorePasswordFinish(password: $password, code: $code, checkId: $checkId)
  }
`);

export const FAST_LOGIN = gql(`
  mutation FastLogin($checkId: String!, $code: String!) {
    fastLogin(checkId: $checkId, code: $code) {
      token
      city {
        id
        name
        region {
          id
          name
          district {
            id
            name
          }
        }
      }
    }
  }
`);
