import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, ErrorAlert } from '../../../../components';
import { SEND_RESTORE_CODE_MUTATION } from '../../../../api/public/login';
import RestorePasswordForm from './RestorePasswordForm/RestorePasswordForm';
import FormHeader from '../../components/FormHeader';

import styles from './RestorePassword.module.scss';

type Props = {
  email: string;
  goBack: () => void;
};

enum Steps {
  'SendCode',
  'Confirmation',
  'Success',
}

export default function RestorePassword({ email, goBack }: Props) {
  const [step, setStep] = useState<number>(Steps.SendCode);
  const [checkId, setCheckId] = useState<string>('');

  const [sendCode, { error, loading }] = useMutation(SEND_RESTORE_CODE_MUTATION, {
    onCompleted: (data) => {
      setCheckId(data.restorePasswordSendCode.checkId);
      setStep(Steps.Confirmation);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  return (
    <>
      {step === Steps.SendCode && (
        <>
          <FormHeader action={goBack} title={'Восстановление пароля'} />
          <h4>{email}</h4>
          <div className={styles.action}>
            <Button
              onClick={() => sendCode({ variables: { email } })}
              loading={loading}
              variant='filled'
            >
              Отправить код
            </Button>
          </div>
          {error && (
            <ErrorAlert
              error={error}
              mapMessage={{ 'Failed to fetch': 'Отсутсвует соединение с сервером' }}
            />
          )}
        </>
      )}
      {step === Steps.Confirmation && (
        <RestorePasswordForm
          onSuccess={() => {
            setStep(Steps.Success);
          }}
          checkId={checkId}
          email={email}
          goBack={goBack}
        />
      )}
      {step === Steps.Success && (
        <>
          <FormHeader action={goBack} title={'Восстановление пароля'} />
          <p>Поздравляем, пароль успешно изменён и вы авторизованы на сайте.</p>
        </>
      )}
    </>
  );
}
