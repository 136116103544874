import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { ErrorAlert, Button } from '../../../../components';
import { LOGIN_MUTATION } from '../../../../api/public/login';
import { useAuth } from '../../../../hooks/useAuth';
import FormHeader from '../../components/FormHeader';

import styles from './LoginForm.module.scss';
import { FormikTextField } from '../../../../components/inputs/FormikTextField/FormikTextField';

const ValidationSchema = Yup.object().shape({
  password: Yup.string().required('Введите пароль'),
});

type Props = {
  email: string;
  goBack: () => void;
  toRestorePass: () => void;
};

export default function LoginForm({ email, goBack, toRestorePass }: Props) {
  const { loginUser } = useAuth();

  const [logIn, { loading, error }] = useMutation(LOGIN_MUTATION, {
    onCompleted: (data) => {
      // FIXME
      // @ts-ignore
      loginUser(data.login);
    }
  });

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      logIn({ variables: { ...values, email } });
    },
  });

  return (
    <form className={styles.formBox} onSubmit={formik.handleSubmit}>
      <FormHeader action={goBack} title={'Вход'} />
      <h4>{email}</h4>
      <FormikTextField field="password" formik={formik} type="password" placeholder="Пароль" />
      <ErrorAlert
        error={error}
        mapMessage={{
          'Failed to fetch': 'Отсутсвует соединение с сервером',
          'user not found': 'Неверный пароль'
        }}
      />
      <a
        onClick={() => {
          toRestorePass();
        }}
        className={styles.link}
      >
        Восстановить пароль
      </a>
      <div className={styles.action}>
        <Button variant='filled' onClick={() => formik.handleSubmit()} loading={loading}>
          Войти
        </Button>
      </div>
    </form>
  );
}
